import { SVGProps, useId } from 'react';

export const ChainLinkVrf = (props: SVGProps<SVGSVGElement>) => {
  const gradientId = useId();

  return (
    <svg viewBox="0 0 35 40" {...props}>
      <g clip-path={`url(#${gradientId})`}>
        <path
          d="M34.8877 30.0005V10.0005L17.5385 0.000488281L0.189331 10.0005V30.0005L17.5385 39.9983L34.8877 30.0005Z"
          fill="white"
        />
        <path
          d="M17.5385 0.000488281L0.189331 10.0005V30.0005L17.5385 40.0005L34.8877 30.0005V10.0005L17.5385 0.000488281ZM28.4348 22.9145C28.4348 22.9145 28.3447 23.2265 28.1363 23.4345L20.9723 30.5865C20.6798 30.8784 20.2551 30.9925 19.8564 30.8845L10.0699 28.2665C9.67129 28.1605 9.36077 27.8485 9.25258 27.4505L6.63018 17.6805C6.524 17.2825 6.63818 16.8585 6.92867 16.5665L14.0927 9.4145C14.237 9.2705 14.4153 9.1685 14.6076 9.1165C14.7999 9.0645 15.0083 9.0625 15.2106 9.1165L24.995 11.7345C25.3657 11.8345 25.6622 12.1105 25.7844 12.4665L28.4509 22.4125C28.4849 22.5805 28.4749 22.7505 28.4328 22.9125L28.4348 22.9145Z"
          fill="#0847F7"
        />
        <path
          d="M28.4348 22.9147L18.1695 20.1667C17.7728 20.0607 17.4603 19.7487 17.3521 19.3507L14.6075 9.11865C14.4172 9.17065 14.2389 9.27065 14.0927 9.41665L6.92859 16.5687C6.6361 16.8607 6.52191 17.2847 6.6301 17.6827L9.2525 27.4527C9.35868 27.8507 9.67121 28.1607 10.0699 28.2687L19.8563 30.8867C20.255 30.9926 20.6797 30.8787 20.9722 30.5886L28.1363 23.4367C28.3426 23.2307 28.4348 22.9167 28.4348 22.9167V22.9147Z"
          fill="#0B101C"
        />
        <path
          d="M20.0728 16.3427C19.5799 16.2107 19.2875 16.5007 19.4177 16.9907C19.5479 17.4807 20.0507 17.9847 20.5436 18.1167C21.0364 18.2487 21.3289 17.9587 21.2007 17.4687C21.0704 16.9787 20.5656 16.4767 20.0748 16.3447L20.0728 16.3427Z"
          fill="#0B101C"
        />
        <path
          d="M22.7112 13.7387C22.2183 13.6067 21.9259 13.8967 22.0561 14.3867C22.1863 14.8767 22.6891 15.3807 23.182 15.5127C23.6748 15.6447 23.9673 15.3547 23.8391 14.8647C23.7089 14.3747 23.204 13.8727 22.7132 13.7407L22.7112 13.7387Z"
          fill="#0B101C"
        />
        <path
          d="M23.5305 17.1725C23.0377 17.0405 22.7452 17.3305 22.8754 17.8205C23.0056 18.3105 23.5085 18.8145 24.0013 18.9465C24.4942 19.0785 24.7867 18.7885 24.6584 18.2985C24.5282 17.8085 24.0234 17.3065 23.5325 17.1745L23.5305 17.1725Z"
          fill="#0B101C"
        />
        <path
          d="M19.1592 12.9486C18.6664 12.8166 18.3739 13.1066 18.5041 13.5966C18.6343 14.0866 19.1372 14.5906 19.63 14.7226C20.1228 14.8546 20.4153 14.5646 20.2871 14.0746C20.1569 13.5846 19.652 13.0826 19.1612 12.9506L19.1592 12.9486Z"
          fill="#0B101C"
        />
      </g>
      <defs>
        <clipPath id={`url(#${gradientId})`}>
          <rect width="35" height="40.0005" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
