import { SVGProps, useId } from 'react';

export const BackgroundSQR = (props: SVGProps<SVGSVGElement>) => {
  const clipPathId = useId();
  const gradientId = useId();

  return (
    <svg viewBox="0 0 33 32" fill="none" {...props}>
      <g clip-path={`url(#${clipPathId})`}>
        <path
          d="M0.5 16C0.5 7.16344 7.66344 0 16.5 0C25.3366 0 32.5 7.16344 32.5 16C32.5 24.8366 25.3366 32 16.5 32C7.66344 32 0.5 24.8366 0.5 16Z"
          fill="#181B25"
        />
        <path
          d="M7.27891 17.4561C6.47474 16.6519 6.47474 15.3481 7.27891 14.5439L8.735 13.0878L10.1911 14.5439C10.9953 15.3481 10.9953 16.6519 10.1911 17.4561C9.38691 18.2602 8.08308 18.2602 7.27891 17.4561Z"
          fill={`url(#${gradientId})`}
        />
        <path
          d="M11.1618 21.339C10.3576 20.5348 10.3576 19.231 11.1618 18.4268L12.6179 16.9707L17.9569 22.3097C18.761 23.1139 18.761 24.4177 17.9569 25.2219C17.1527 26.026 15.8489 26.026 15.0447 25.2219L11.1618 21.339Z"
          fill={`url(#${gradientId})`}
        />
        <path
          d="M12.6179 9.20493L9.70572 12.1171L20.3837 22.795L23.2958 19.8829L12.6179 9.20493Z"
          fill={`url(#${gradientId})`}
        />
        <path
          d="M15.0447 9.69029C14.2405 8.88611 14.2405 7.58229 15.0447 6.77812C15.8489 5.97394 17.1527 5.97394 17.9569 6.77812L21.8398 10.661C22.6439 11.4652 22.6439 12.769 21.8398 13.5732L20.3837 15.0293L15.0447 9.69029Z"
          fill={`url(#${gradientId})`}
        />
        <path
          d="M22.8105 14.5439C22.0063 15.3481 22.0063 16.6519 22.8105 17.4561L24.2666 18.9122L25.7227 17.4561C26.5268 16.6519 26.5268 15.3481 25.7227 14.5439C24.9185 13.7397 23.6147 13.7397 22.8105 14.5439Z"
          fill={`url(#${gradientId})`}
        />
      </g>
      <defs>
        <linearGradient
          id={gradientId}
          x1="21.4133"
          y1="6.17499"
          x2="16.3388"
          y2="25.7831"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF8066" />
          <stop offset="0.5" stop-color="#E666FF" />
          <stop offset="1" stop-color="#66B3FF" />
        </linearGradient>
        <clipPath id={`url(#${clipPathId})`}>
          <path
            d="M0.5 16C0.5 7.16344 7.66344 0 16.5 0C25.3366 0 32.5 7.16344 32.5 16C32.5 24.8366 25.3366 32 16.5 32C7.66344 32 0.5 24.8366 0.5 16Z"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
